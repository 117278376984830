* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


html, body {
  overflow-x: hidden;
}

.stroke-text {
  color: transparent;
  -webkit-text-stroke: 1px white;
}

/* our-services-slider */
.our-services-wrapper .swiper-pagination {
  text-align: left;
  position: relative;
}

.our-services-wrapper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 55px;
  height: 6px;
  border-bottom: solid 6px #D82520;
  border-radius: 0;
}

.our-services-wrapper .swiper-pagination-bullet{
  width: 55px;
  height: 3px;
  
  border-radius: 0;
}

/* last-posts-carousel */
.last-posts-carousel-wrapper .swiper-pagination {
  text-align: left;
  z-index: 10000;
}

.last-posts-carousel-wrapper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 55px;
  height: 6px;
  background-color: #D82520;
  border-radius: 0;
}

.last-posts-carousel-wrapper .swiper-pagination-bullet{
  width: 55px;
  height: 3px;
  
  border-radius: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
