.post-item-wrapper h1 {
    margin-bottom: 8px;
    font-family: 'Archivo', sans-serif;
    font-size: 35px;
}

.post-item-wrapper h2 {
    margin-bottom: 8px;
    font-family: 'Archivo', sans-serif;
    font-size: 22px;
}

.post-item-wrapper h3 {
    margin-bottom: 8px;
    font-family: 'Archivo', sans-serif;
    font-size: 18px;
}